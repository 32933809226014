#development-work-experience {
  margin: 0 0 0 70px;
  padding: 0;

  li {
    margin: 0 20px;
    padding: 0;
    border-top: double 3px #a00;
    border-left: dotted 1px #faa;
    display: block;
    width: 100%;
    background-color: white;
    clear: both;
    min-height: 150px;
    transition: all 500ms ease-out;

    &:hover {
      color: #400;
      background-color: #fffcfa;
      cursor: pointer;

      p {
        font-weight: bold;
      }
    }

    &:first-child {
      border-top: dotted 1px #faa;
    }

    &:last-child {
      border-bottom: dotted 1px #faa;
    }

    div.logo {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 300px;
      height: 150px;
      transition: all 500ms ease-out;
      text-indent: -9999px;
    }

    p {
      width: auto;
      padding: 24px 20px;
      position: absolute;
      font-size: 17px;
      line-height: 20px;

      a {
        font-family: Oswald, Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-size: .9em;
        text-decoration: underline;
      }
    }

    &:nth-child(odd) {
      p {
        left: 400px;
        margin-right: 60px;
      }

      div.logo {
        float: left;
      }
    }

    &:nth-child(even) {
      p {
        margin-right: 360px;
      }

      div.logo {
        float: right;
      }
    }

    &#oppenheimer-funds {
      div.logo {
        background-image: url('/images/companies/oppenheimer-funds.jpg');
      }
    }

    &#morgan-stanley {
      div.logo {
        background-image: url('/images/companies/morgan-stanley.jpg');
      }
    }

    &#subway-restaurants {
      div.logo {
        background-image: url('/images/companies/subway-restaurants.png');
      }
    }

    &#gartner {
      div.logo {
        background-image: url('/images/companies/gartner.jpg');
      }
    }

    &#mckinsey-and-company {
      div.logo {
        background-image: url('/images/companies/mckinsey-and-company.png');
      }
    }
  }
}
