.mdl-card {
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  width: 100%;

  @import 'featured';
  @import 'companies';

  &> * {
    height: auto;
  }

  h4, h5 {
    font-family: Oswald, Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin-top: 0;
    margin-bottom: 20px;
    background-color: #fcfcfc;
    z-index: 5;
    display: block;
    position: relative;
  }

  h4 {
    font-weight: 700;
    font-size: 30px;
    color: #d00;
  }

  h5 {
    font-size: 20px;
    font-weight: 400;
    color: #e00;
    margin-left: 90px;
    opacity: 0.9;
  }

  .mdl-card__supporting-text {
    margin: 20px;
    flex-grow: 1;
    padding: 0;
    color: inherit;
    width: calc(100% - 80px);

    p {
      font-size: 15px;
      font-family: Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-weight: 300;

      &.font-size-up {
        font-size: 1.2em;
      }

      img.bio-pic {
        float: left;
        width: 100px;
        margin: 0 10px 10px 0;
        border-bottom: double 3px #a00;
      }
    }

    &+ .mdl-card__actions {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    &> h3:first-child {
      margin-bottom: 24px;
    }
  }

  .mdl-card__actions {
    margin: 0;
    padding: 4px 0;
    color: inherit;

    a {
      color: red;
      margin: 0;
      font-family: Oswald, Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-weight: 700;
      text-transform: none;
      letter-spacing: .05em;
      font-size: 20px;

      &:hover, &:active {
        color: inherit;
        background-color: transparent;
      }
    }
  }
}
