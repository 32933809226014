footer.mdl-mega-footer {
  background: linear-gradient(to bottom, #c91a1a, #781d1d);
  font-size: 13px;
  margin-top: 20px;

  .mdl-mega-footer--heading, .mdl-mega-footer__heading {
    font-family: Oswald, Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.05em;
  }
}
