.page-not-found {
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /*******************************************************************************
  Theme Styles
  *******************************************************************************/

  body {
    box-sizing: border-box;
    color:#373737;
    background: #212121;
    font-size: 16px;
    font-family: 'Myriad Pro', Calibri, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 10px 0;
    font-weight: 700;
    color:#222222;
    font-family: 'Lucida Grande', 'Calibri', Helvetica, Arial, sans-serif;
    letter-spacing: -1px;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
  }

  h2 {
    padding-bottom: 10px;
    font-size: 32px;
    background: url('../images/bg_hr.png') repeat-x bottom;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 21px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  p {
    margin: 10px 0 15px 0;
  }

  footer p {
    color: #f2f2f2;
  }

  a {
    text-decoration: none;
    color: #007edf;
    text-shadow: none;

    transition: color 0.5s ease;
    transition: text-shadow 0.5s ease;
    -webkit-transition: color 0.5s ease;
    -webkit-transition: text-shadow 0.5s ease;
    -moz-transition: color 0.5s ease;
    -moz-transition: text-shadow 0.5s ease;
    -o-transition: color 0.5s ease;
    -o-transition: text-shadow 0.5s ease;
    -ms-transition: color 0.5s ease;
    -ms-transition: text-shadow 0.5s ease;
  }

  a:hover, a:focus {text-decoration: underline;}

  footer a {
    color: #F2F2F2;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  img {
    position: relative;
    margin: 0 auto;
    max-width: 739px;
    padding: 5px;
    margin: 10px 0 10px 0;
    border: 1px solid #ebebeb;

    box-shadow: 0 0 5px #ebebeb;
    -webkit-box-shadow: 0 0 5px #ebebeb;
    -moz-box-shadow: 0 0 5px #ebebeb;
    -o-box-shadow: 0 0 5px #ebebeb;
    -ms-box-shadow: 0 0 5px #ebebeb;
  }

  p img {
    display: inline;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    border: none;
  }

  pre, code {
    width: 100%;
    color: #222;
    background-color: #fff;

    font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace;
    font-size: 14px;

    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
  }

  pre {
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    overflow: auto;
  }

  code {
    padding: 3px;
    margin: 0 3px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
  }

  pre code {
    display: block;
    box-shadow: none;
  }

  blockquote {
    color: #666;
    margin-bottom: 20px;
    padding: 0 0 0 20px;
    border-left: 3px solid #bbb;
  }


  ul, ol, dl {
    margin-bottom: 15px
  }

  ul {
    list-style-position: inside;
    list-style: disc;
    padding-left: 20px;
  }

  ol {
    list-style-position: inside;
    list-style: decimal;
    padding-left: 20px;
  }

  dl dt {
    font-weight: bold;
  }

  dl dd {
    padding-left: 20px;
    font-style: italic;
  }

  dl p {
    padding-left: 20px;
    font-style: italic;
  }

  hr {
    height: 1px;
    margin-bottom: 5px;
    border: none;
    background: url('../images/bg_hr.png') repeat-x center;
  }

  table {
    border: 1px solid #373737;
    margin-bottom: 20px;
    text-align: left;
  }

  th {
    font-family: 'Lucida Grande', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 10px;
    background: #373737;
    color: #fff;
  }

  td {
    padding: 10px;
    border: 1px solid #373737;
  }

  form {
    background: #f2f2f2;
    padding: 20px;
  }

  /*******************************************************************************
  Full-Width Styles
  *******************************************************************************/

  .outer {
    width: 100%;
  }

  .inner {
    position: relative;
    max-width: 640px;
    padding: 20px 10px;
    margin: 0 auto;
  }

  #forkme_banner {
    display: block;
    position: absolute;
    top:0;
    right: 10px;
    z-index: 10;
    padding: 10px 50px 10px 10px;
    color: #fff;
    background: url('../images/blacktocat.png') #0090ff no-repeat 95% 50%;
    font-weight: 700;
    box-shadow: 0 0 10px rgba(0,0,0,.5);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  #header_wrap {
    background: #212121;
  }

  #header_wrap .inner {
    padding: 50px 10px 30px 10px;
  }

  #project_title {
    margin: 0;
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    text-shadow: #111 0px 0px 10px;
  }

  #project_tagline {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    background: none;
    text-shadow: #111 0px 0px 10px;
  }

  #downloads {
    position: absolute;
    width: 210px;
    z-index: 10;
    bottom: -40px;
    right: 0;
    height: 70px;
    background: url('../images/icon_download.png') no-repeat 0% 90%;
  }

  .zip_download_link {
    display: block;
    float: right;
    width: 90px;
    height:70px;
    text-indent: -5000px;
    overflow: hidden;
    background: url(../images/sprite_download.png) no-repeat bottom left;
  }

  .tar_download_link {
    display: block;
    float: right;
    width: 90px;
    height:70px;
    text-indent: -5000px;
    overflow: hidden;
    background: url(../images/sprite_download.png) no-repeat bottom right;
    margin-left: 10px;
  }

  .zip_download_link:hover {
    background: url(../images/sprite_download.png) no-repeat top left;
  }

  .tar_download_link:hover {
    background: url(../images/sprite_download.png) no-repeat top right;
  }

  #main_content_wrap {
    background: #f2f2f2;
    border-top: 1px solid #111;
    border-bottom: 1px solid #111;
  }

  #main_content {
    padding-top: 40px;
  }

  #footer_wrap {
    background: #212121;
  }



  /*******************************************************************************
  Small Device Styles
  *******************************************************************************/

  @media screen and (max-width: 480px) {
    body {
      font-size:14px;
    }

    #downloads {
      display: none;
    }

    .inner {
      min-width: 320px;
      max-width: 480px;
    }

    #project_title {
      font-size: 32px;
    }

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 21px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    code, pre {
      min-width: 320px;
      max-width: 480px;
      font-size: 11px;
    }
  }
}
