#crates-media-logo {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  z-index: 10;
  border-bottom: solid 1px white;

  &:before {
    content: ' ';
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 20), rgba(255, 255, 255, 80), white);
    width: 20px;
    left: -20px;
    height: 237px;
    position: absolute;
    display: block;
  }

  img {
    width: auto;
    height: 240px;
    margin-top: 1px;
    border-bottom: solid 2px white;
  }
}

.mdl-layout__header {
  background-color: transparent !important;

  .mdl-layout__tab-bar-container {
    height: 64px;

    .mdl-layout__tab-bar {
      background: linear-gradient(to top, rgb(101, 25, 25), rgb(207, 26, 26)) !important;
      padding: 0;
      padding-left: 16px;
      box-sizing: border-box;
      height: 100%;
      width: 100%;

      a.mdl-layout__tab {
        height: 64px;
        line-height: 64px;
        font-family: Oswald, Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-weight: 400;
        color: hsla(0,0%,100%,.8);

        &.is-active, &:hover {
          color: white;

          &:after {
            height: 4px;
            background-color: red;
          }
        }
      }
    }

    .mdl-layout__tab-bar-button {
      display: none;
    }
  }

  h1, h2, h3 {
    padding-left: 20px;
    margin: 0;
    opacity: 0.9;
    color: #c00;
  }

  h1, h2 {
    font-family: Oswald, Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  h1 {
    font-weight: 700;
    letter-spacing: 0.02em;
    color: red;
  }

  h2 {
    font-weight: 400;
    letter-spacing: 0.05em;
    font-size: 38px;
    line-height: 47px;
    margin-top: -5px;
    margin-bottom: 5px;
  }

  h3 {
    margin-top: -1px;
    letter-spacing: normal;
    font-size: 27px;
    color: #900;
    font-weight: 200;
    font-family: 'Roboto', sans-serif;
  }
}

&.is-small-screen .mdl-layout__header {
  h1, h2, h3 {
    line-height: normal;
    opacity: 1;
  }

  h1 {
    font-weight: 400;
    font-size: 25px;
    letter-spacing: 0.08em;
  }

  h2 {
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 0;
  }

  h3 {
    margin-top: -1px;
    font-size: 12px;
    letter-spacing: normal;
  }
}

&:not(.is-small-screen) {
  .mdl-layout__tab-bar,
  .mdl-layout__tab-bar-container {
    overflow: visible;
  }

  .mdl-layout__header {
    height: 227px;

    h1, h2, h3 {
      overflow: hidden;
      white-space: nowrap;
    }

    h1 {
      height: 76px;
      font-size: 57px;
    }
  }
}
