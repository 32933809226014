@import url('https://fonts.googleapis.com/css?family=Oswald:400,700|Roboto:200,300');
@import 'material';

html, body {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

@import 'media';
@import '404';

body.mdl-demo {
  background-color: white !important;
  background-image: url('/images/lace.png');
  background-position: 0% 98%;
  background-repeat: repeat-x;
  background-size: 360px;

  &.loaded {
    #loading-wrapper {
      opacity: 0 !important;
      margin-top: -2000px !important;
      color: white !important;

      p {
        display: none;
        visibility: hidden;
      }
    }
  }

  .mdl-layout {
    position: relative;
    background-image: url('/images/michael-sean-crates-mcdade.png');
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: 101px;

    a {
      color: #f00;
    }

    header, main {
      margin: 0 auto;
      max-width: 1440px;
    }

    @import 'header';

    .mdl-grid {
      background-color: #fcfcfc;

      @import 'cards/card';
    }

    .mdl-layout__tab-panel {
      padding: 8px;
      padding-top: 20px;
    }

    .mdl-layout__content {
      section:not(:last-of-type) {
        position: relative;
        margin-bottom: 48px;
      }
    }

    section {
      &.section--center {
        max-width: 1240px;
      }

      & > header {
        display: flex;
        align-items: center;
        justify-content: center;

        & > .material-icons {
          font-size: 3rem;
        }
      }

      & > .section__play-btn {
        min-height: 200px;
      }

      & > button {
        position: absolute;
        z-index: 99;
        top: 8px;
        right: 8px;
      }

      .section__circle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 0;
        flex-shrink: 1;
      }

      .section__text {
        flex-grow: 1;
        flex-shrink: 0;
        padding-top: 8px;

        h5 {
          font-size: inherit;
          margin: 0;
          margin-bottom: 0.5em;
        }

        a {
          text-decoration: none;
        }
      }

      .section__circle-container > .section__circle-container__circle {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        margin: 8px 0;
      }

      &.section--center .section__text:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, .13);
      }

      &.section--footer {
        padding: 10px 0 0;
        margin: 0 -8px -8px -8px;
      }
    }

   @import 'footer';
  }

  .toc {
    border-left: 4px solid #C1EEF4;
    margin: 24px;
    padding: 0;
    padding-left: 8px;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 0.9rem;
      margin-top: 0;
    }

    a {
      color: #4DD0E1;
      text-decoration: none;
      font-size: 16px;
      line-height: 28px;
      display: block;
    }
  }

  .mdl-menu__container {
    z-index: 99;
  }
}
