@media (min-width: 1025px) and (max-width: 1300px) {
  html > body.mdl-demo {
    div#crates-media-logo {
      &:before {
        content: '';
        display: none;
        visibility: hidden;
        background: transparent;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1236px) {
  html > body.mdl-demo .mdl-layout {
    background-position: 95% 0%;

    div#crates-media-logo {
      display: none;
    }
  }
}

@media (min-width: 1236px) and (max-width: 1600px) {
  html > body.mdl-demo .mdl-layout {
    background-image: none;
  }
}

@media (min-width: 1360px) {
  html > body.mdl-demo .mdl-layout {
    #front-end-developer-experience {
      margin-left: -50px;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1624px) {
  body.mdl-demo {
    .mdl-layout {
      background-size: 80px;
      background-position: 85% 0%;
    }
  }
}

@media (max-width: 1024px) {
  html > body.mdl-demo .mdl-layout {
    background-image: none;

    div#crates-media-logo {
      &:before {
        max-height: 134px;
      }

      img {
        height: 132px;
        max-height: 132px;
        border-bottom: none;
      }
    }
  }
}

@media (min-width: 671px) and (max-width: 1240px) {
  html > body.mdl-demo .mdl-layout {
    section.section--center {
      max-width: 840px;
    }
  }
}

@media (max-width: 670px) {
  html > body.mdl-demo .mdl-layout {
    section.section--center {
      max-width: 480px;
    }

    .hide-on-small-sizes {
      display: none;
    }
  }
}

@media (max-width: 515px) {
  html > body.mdl-demo {
    div#crates-media-logo {
      display: none;
      visibility: hidden;
    }
  }
}

@media (min-width: 1240px) {
  html > body.mdl-demo {
    .mdl-layout .mdl-grid {
      .mdl-card #development-work-experience {
        li p {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }
}

@media (min-width: 826px) {
  html > body.mdl-demo {
    .mdl-layout .mdl-grid {
      .mdl-card #development-work-experience {
        a.more-info-work-experience {
          display: block;
          text-align: right;
          padding-right: 30px;
        }
      }
    }
  }
}

@media (min-width: 766px) and (max-width: 1024px) {
  html > body.mdl-demo {
    .mdl-layout .mdl-grid {
      .mdl-card #development-work-experience li {
        min-height: 120px;

        p {
          font-size: 14px;
          line-height: 19px;
        }

        &:nth-child(odd) {
          left: 300px;
        }

        &:nth-child(even) {
          p {
            margin-right: 240px;
          }
        }

        div.logo {
          width: 240px;
          height: 120px;
        }
      }
    }
  }
}

@media (min-width: 671px) and (max-width: 765px) {
  html > body.mdl-demo {
    div#crates-media-logo {
      display: none;
      visibility: hidden;
    }

    .mdl-layout .mdl-grid {
      .mdl-card #development-work-experience li {
        min-height: 80px;

        p {
          font-size: 13px;
          line-height: 17px;
          padding-top: 6px;
        }

        &:nth-child(odd) p {
          left: 250px;
        }

        &:nth-child(even) p {
          margin-right: 160px;
        }

        div.logo {
          width: 160px;
          height: 80px;
        }
      }
    }
  }
}

@media (max-width: 670px) {
  html > body.mdl-demo {
    .mdl-layout.is-small-screen {
      .mdl-layout__header-row h3 {
        font-size: inherit;
      }

      .mdl-layout__tab-bar .mdl-button {
        display: none;
      }

      section.section--footer .section__circle--big {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        margin: 8px 16px;
      }

      .mdl-grid {
        .mdl-card {
          h5 {
            margin-left: 20px;
            font-size: 18px;
          }

          .mdl-card__actions {
            .mdl-button, a {
              font-size: 14px;
              letter-spacing: 0.02em;
            }
          }

          #development-work-experience {
            margin-left: 0;

            li {
              min-height: 60px;

              p {
                font-size: 10px;
                line-height: 14px;
                padding-top: 2px;
              }

              &:nth-child(odd) p {
                left: 150px;
              }

              &:nth-child(even) p {
                margin-right: 120px;
                margin-top: 1px;
              }

              div.logo {
                width: 120px;
                height: 60px;
              }

              &#gartner {
                div.logo {
                  width: 135px;
                }

                p {
                  margin-right: 140px;
                }
              }
            }
          }
        }
      }
    }

    .mdl-layout__tab-bar .mdl-layout__tab {
      font-size: 11px;
      padding: 0 4px;
    }
  }
}

@media (max-width: 500px) {
  html > body.mdl-demo {
    .mdl-layout.is-small-screen {
      .mdl-grid {
        .mdl-card {
          #development-work-experience {
            li, li#gartner {
              p {
                padding: 8px 16px;
                margin-top: 5px;
              }

              &:nth-child(odd) p {
                left: 40px;
              }

              &:nth-child(even) p {
                margin-right: 50px;
              }

              div.logo {
                width: 100%;
                background-image: none !important;
                text-indent: 0;
                font-weight: 700;
                font-family: Oswald, Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
                font-size: 11px;
                margin-top: -7px;
                color: #a00;
              }
            }
          }
        }
      }
    }
  }
}
