.framework-logos {
  float: right;
  width: 150px;
  text-align: right;
  margin-right: -15px;

  img {
    width: 150px;
    display: block;

    &:first-child {
      margin-top: -30px;
    }

    &:last-child {
      margin-top: 20px;
    }
  }
}
